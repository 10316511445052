import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewRef } from "@angular/core";
import { ModalService } from "../modal/modal.service";
import { Task } from "../../pojo/Task";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
export interface TaskEditI {
    itemName: string;
    itemTypeId: Task.Id;
    itemId: number;
    eventId: number;
    itemStateId: Task.State;
    assignedToId: number;
    isTodo: boolean;
    todoType: Task.Todo.Type;
    listModelBean: any;
    showClass?: boolean;
    taskBlocked?: boolean;
    workflowChained?: boolean;
    itemQuantity?: string | number;
    objectType?: number;
}

@TypeManagerDecorator("s25-ng-task-edit")
@Component({
    selector: "s25-ng-task-edit",
    template: `
        <span
            class="ngBold ngInlineBlock {{ hasModal ? 'ngAnchor' : '' }}"
            (click)="click()"
            (keydown.enter)="click()"
            tabindex="0"
        >
            @if (this.showIcon) {
                <span>
                    @if (taskModel.itemStateId === 1) {
                        <svg class="c-svgIcon" role="img">
                            <title>Task</title>
                            <use
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#objects--tasks-default"
                            ></use>
                        </svg>
                    }
                    @if (taskModel.itemStateId === 3 || taskModel.itemStateId === 99) {
                        <svg class="c-svgIcon" role="img">
                            <title>Task</title>
                            <use
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#objects--tasks-cancelled"
                            ></use>
                        </svg>
                    }
                    @if (taskModel.itemStateId === 2) {
                        <svg class="c-svgIcon" role="img">
                            <title>Task</title>
                            <use
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#objects--tasks-completed"
                            ></use>
                        </svg>
                    }
                </span>
            }

            {{ taskModel.itemName }}
            @if (taskModel.objectType === 6 && taskModel.itemQuantity) {
                <span class="resource-quantity">{{ " [Quantity: " + taskModel.itemQuantity + "]" }}</span>
            }
        </span>
    `,
})
export class S25TaskEditComponent implements OnInit {
    @Input() taskModel: TaskEditI;
    @Input() showIcon?: boolean = false;
    @Input() taskBlocked?: boolean = false;
    @Output() onRefresh = new EventEmitter();
    hasModal: boolean;
    taskIcon: string = "";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        let itemTypeId = S25Util.parseInt(this.taskModel.itemTypeId);
        //only these task types have modals (not v-cal to-dos (type 6)...) OR taslBlocked and workflowChained
        this.hasModal = [1, 2, 3, 4, 5].indexOf(itemTypeId) > -1 && !this.taskBlocked;
        if (this.taskModel.taskBlocked && this.taskModel.workflowChained) {
            this.hasModal = false;
        }

        this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
        // this.cd.detectChanges();
    }

    click() {
        let modalItem = {
            taskType: S25Util.parseInt(this.taskModel.itemTypeId),
            taskId: this.taskModel.itemId,
            type: "edit",
            eventId: this.taskModel.eventId,
            taskActionsCell: this.taskModel,
            listModelBean: this.taskModel.listModelBean,
            taskBlocked: this.taskBlocked,
            editCompCallBack: this.editCompCallBack,
        };
        if (this.hasModal) {
            ModalService.modal("create-edit-task", modalItem);
        }
    }

    //need to call back and refresh the dataset
    editCompCallBack = (callback: () => void) => {
        this.onRefresh.emit();
    };

    refresh() {
        this.cd.detectChanges();
    }
}
