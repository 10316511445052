import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { TaskService } from "../../services/task/task.service";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { TaskTiersService } from "../../services/task/task.tiers.service";

@TypeManagerDecorator("s25-ng-task-status")
@Component({
    selector: "s25-ng-task-status",
    template: `
        @if (!workflowChained || !isBlocked) {
            <div class="{{ className }}">{{ state }}</div>
        }
        @if (workflowChained && isBlocked) {
            <s25-ng-task-inactive
                [task]="{ blockTask: task.blocking_task, taskName: task.task_name }"
            ></s25-ng-task-inactive>
        }
    `,
    styles: `
        .in_progress {
            color: #666;
        }

        .various {
            font-style: italic;
        }

        .assigned,
        .approved,
        .acknowledged,
        .completed {
            color: green;
        }

        .ignored,
        .denied {
            color: #c00;
        }

        .cancelled,
        .cancelled * {
            text-decoration: none !important;
            color: inherit !important;
        }

        ::ng-deep .nm-party--on s25-ng-task-status .in_progress {
            color: rgba(255, 255, 255, 0.73);
        }

        ::ng-deep .nm-party--on s25-ng-task-status .assigned,
        ::ng-deep .nm-party--on s25-ng-task-status .approved,
        ::ng-deep .nm-party--on s25-ng-task-status .acknowledged,
        ::ng-deep .nm-party--on s25-ng-task-status .completed {
            color: #40c47e;
        }

        ::ng-deep .nm-party--on s25-ng-task-status .ignored,
        ::ng-deep .nm-party--on s25-ng-task-status .denied {
            color: #ff8585;
        }
    `,
})
export class S25TaskStatusComponent implements OnInit {
    @Input() task: any;

    isInit = false;
    workflowChained: boolean;
    isBlocked: boolean;
    state: string;
    className: string;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        this.workflowChained = await TaskTiersService.isWorkflowChained();

        this.isBlocked = this.task.task_blocked === "T";
        this.setStateText();
    }

    setStateText() {
        this.state = TaskService.taskStateToStateText(this.task.task_state, this.task.task_type);
        this.className = this.state.toLowerCase().replace(/\s/, "_");
    }

    refresh() {
        this.isBlocked = this.task.task_blocked === "T";
        this.setStateText();
        this.changeDetector.detectChanges();
    }
}
