/*
     itemTypeId = 4 unassign
     objectType = 7   eventType
     objectType = 8 requirement
     notifyTypes >>   Notify only = 1
      notifyTypes >>  Approval required = 2
    */

import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild, ViewRef } from "@angular/core";

import { Task } from "../../pojo/Task";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { TaskService } from "../../services/task/task.service";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { CacheRepository } from "../../decorators/cache.decorator";
import { S25CheckboxComponent } from "../s25-checkbox/s25.checkbox.component";
import { S25EditableTextareaComponent } from "../s25-editable/s25-editable-textarea/s25.editable.textarea.component";
import { S25EditableDateComponent } from "../s25-editable/s25-editable-date/s25.editable.date.component";
import { S25TaskEditComponent } from "./s25.task.edit.component";
import { S25TaskAssignComponent } from "./s25.task.assign.component";
import { Api } from "../../api/api";
import { ContactService } from "../../services/contact.service";
import { TaskViewApi } from "../s25-task-view/s25.task.view.component";
import { jSith } from "../../util/jquery-replacement";
import { S25TaskInactiveComponent } from "./s25.task.inactive.component";
import TaskList = Task.TaskListI;
import { S25TaskActionComponent } from "./s25.task.action.component";
import { S25TaskStatusComponent } from "./s25.task.status.component";
import { TaskNormalizeUtil } from "../../services/task/task.normalize.util";

export class TaskWorkflowApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        return Api.callApiFn(
            relativeElem,
            "s25-ng-task-workflow-view",
            null,
            null,
            (comp: S25TaskWorkflowComponent) => {
                comp.refreshF();
            },
        );
    }
}

@TypeManagerDecorator("s25-ng-task-workflow-view")
@Component({
    selector: "s25-ng-task-workflow-view",
    template: `
        @if (init) {
            <div class="task-wrapper">
                <div class="c-margin-bottom--half">
                    <s25-ng-progress-bar
                        [levels]="levels"
                        [currentLevel]="progressLevel"
                        [complete]="allTasksComplete"
                    ></s25-ng-progress-bar>
                    <s25-simple-collapse
                        [headerText]="'Event Type and Organization Approvals ' + tier1BarText"
                        [defaultCollapsed]="true"
                        [expanded]="tier1Expand"
                    >
                        @if (initTier1 && tier1InProgress.length > 0) {
                            <s25-ng-task-buttons
                                [modelBean]="tier1SelectedItem"
                                (onRefresh)="onTaskButtonRefresh()"
                            ></s25-ng-task-buttons>
                        }
                        <s25-ng-table
                            [dataSource]="tableConfigTier1"
                            [columnSortable]="true"
                            [hasFilter]="false"
                            [hasRefresh]="false"
                            [pivotThreshold]="800"
                            [hasSelect]="tier1InProgress.length > 0"
                            [hasSelectAll]="tier1InProgress.length > 0"
                            [noResultsMessage]="'No Tasks Found'"
                            (selectedChange)="selectedChange($event, 'tier1')"
                        ></s25-ng-table>
                        @if (initTier1 && tier1InProgress.length > 0) {
                            <s25-ng-task-buttons
                                [modelBean]="tier1SelectedItem"
                                (onRefresh)="onTaskButtonRefresh()"
                            ></s25-ng-task-buttons>
                        }
                    </s25-simple-collapse>
                    <!-- tier 2 -->
                    <div class="c-margin-top--half c-margin-bottom--half">
                        <s25-simple-collapse
                            [headerText]="'Location Assignments and Approvals ' + tier2BarText"
                            [defaultCollapsed]="true"
                            [expanded]="tier2Expand"
                        >
                            @if (initTier2 && tier2Count > 0 && tier2Block.length === 0 && tier2InProgress.length > 0) {
                                <s25-ng-task-buttons
                                    [modelBean]="tier2SelectedItem"
                                    (onRefresh)="onTaskButtonRefresh()"
                                ></s25-ng-task-buttons>
                            }
                            <s25-ng-table
                                [dataSource]="tableConfigTier2"
                                [columnSortable]="true"
                                [hasFilter]="false"
                                [hasRefresh]="false"
                                [pivotThreshold]="800"
                                [hasSelect]="!(tier2Block.length > 0 || tier2InProgress.length === 0)"
                                [hasSelectAll]="!(tier2Block.length > 0 || tier2InProgress.length === 0)"
                                [noResultsMessage]="'No Tasks Found'"
                                (selectedChange)="selectedChange($event, 'tier2')"
                            ></s25-ng-table>
                            @if (initTier2 && tier2Count > 0 && tier2Block.length === 0 && tier2InProgress.length > 0) {
                                <s25-ng-task-buttons
                                    [modelBean]="tier2SelectedItem"
                                    (onRefresh)="onTaskButtonRefresh()"
                                ></s25-ng-task-buttons>
                            }
                        </s25-simple-collapse>
                    </div>
                    <!-- tier 3 -->
                    <div class="c-margin-top--half">
                        <s25-simple-collapse
                            [headerText]="'Resource Assignments and Approvals ' + tier3BarText"
                            [defaultCollapsed]="true"
                            [expanded]="tier3Expand"
                        >
                            @if (initTier3 && tier3Count > 0 && tier3Block.length === 0 && tier3InProgress.length > 0) {
                                <s25-ng-task-buttons
                                    [modelBean]="tier3SelectedItem"
                                    (onRefresh)="onTaskButtonRefresh()"
                                ></s25-ng-task-buttons>
                            }
                            <s25-ng-table
                                [dataSource]="tableConfigTier3"
                                [columnSortable]="true"
                                [hasFilter]="false"
                                [hasRefresh]="false"
                                [pivotThreshold]="800"
                                [hasSelect]="!(tier3Block.length > 0 || tier3InProgress.length === 0)"
                                [hasSelectAll]="!(tier3Block.length > 0 || tier3InProgress.length === 0)"
                                [noResultsMessage]="'No Tasks Found'"
                                (selectedChange)="selectedChange($event, 'tier3')"
                            ></s25-ng-table>
                            @if (initTier3 && tier3Count > 0 && tier3Block.length === 0 && tier3InProgress.length > 0) {
                                <s25-ng-task-buttons
                                    [modelBean]="tier3SelectedItem"
                                    (onRefresh)="onTaskButtonRefresh()"
                                ></s25-ng-task-buttons>
                            }
                        </s25-simple-collapse>
                    </div>
                    <!-- tier 4 -->
                    <div class="c-margin-top--half">
                        <s25-simple-collapse
                            [headerText]="'Requirement Approvals ' + tier4BarText"
                            [defaultCollapsed]="true"
                            [expanded]="tier4Expand"
                        >
                            @if (initTier4 && tier4Count > 0 && tier4Block.length === 0 && tier4InProgress.length > 0) {
                                <s25-ng-task-buttons
                                    [modelBean]="tier4SelectedItem"
                                    (onRefresh)="onTaskButtonRefresh()"
                                ></s25-ng-task-buttons>
                            }
                            <s25-ng-table
                                [dataSource]="tableConfigTier4"
                                [columnSortable]="true"
                                [hasFilter]="false"
                                [hasRefresh]="false"
                                [pivotThreshold]="800"
                                [hasSelect]="!(tier4Block.length > 0 || tier4InProgress.length === 0)"
                                [hasSelectAll]="!(tier4Block.length > 0 || tier4InProgress.length === 0)"
                                [noResultsMessage]="'No Tasks Found'"
                                (selectedChange)="selectedChange($event, 'tier4')"
                            ></s25-ng-table>
                            @if (initTier4 && tier4Count > 0 && tier4Block.length === 0 && tier4InProgress.length > 0) {
                                <s25-ng-task-buttons
                                    [modelBean]="tier4SelectedItem"
                                    (onRefresh)="onTaskButtonRefresh()"
                                ></s25-ng-task-buttons>
                            }
                        </s25-simple-collapse>
                    </div>
                    <!-- tier5  to-do FYIs -->
                    <div class="c-margin-top--half">
                        <div class="c-margin-top--half"></div>
                        <s25-simple-collapse
                            [headerText]="'To Dos and FYIs ' + tier5BarText"
                            [defaultCollapsed]="true"
                            [expanded]="tier5Expand"
                        >
                            @if (initTier5 && tier5Count > 0) {
                                <s25-ng-task-buttons
                                    [modelBean]="tier5SelectedItem"
                                    (onRefresh)="onTaskButtonRefresh()"
                                ></s25-ng-task-buttons>
                            }
                            <s25-ng-table
                                [dataSource]="tableConfigTier5"
                                [columnSortable]="true"
                                [hasFilter]="false"
                                [hasRefresh]="false"
                                [pivotThreshold]="800"
                                [hasSelect]="true"
                                [hasSelectAll]="true"
                                [noResultsMessage]="'No Tasks Found'"
                                (selectedChange)="selectedChange($event, 'tier5')"
                            ></s25-ng-table>
                            @if (initTier5 && tier5Count > 0) {
                                <s25-ng-task-buttons
                                    [modelBean]="tier5SelectedItem"
                                    (onRefresh)="onTaskButtonRefresh()"
                                ></s25-ng-task-buttons>
                            }
                        </s25-simple-collapse>
                    </div>
                </div>
            </div>
        }
    `,
})
export class S25TaskWorkflowComponent implements OnInit {
    @Input() modelBean: any;
    @Input() deferred?: any;
    @Input() optBean?: any;

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    tableConfigTier1: Table.DataSource;
    tableConfigTier2: Table.DataSource;
    tableConfigTier3: Table.DataSource;
    tableConfigTier4: Table.DataSource;
    tableConfigTier5: Table.DataSource;

    tasksById: Record<number, TaskList> = {};

    hasModal: boolean;
    init: boolean;

    initTier1: boolean;
    initTier2: boolean;
    initTier3: boolean;
    initTier4: boolean;
    initTier5: boolean;

    tier1Count: number;
    tier2Count: number;
    tier3Count: number;
    tier4Count: number;
    tier5Count: number;

    tier1Block: TaskList[] = [];
    tier2Block: TaskList[] = [];
    tier3Block: TaskList[] = [];
    tier4Block: TaskList[] = [];

    tier1InProgress: TaskList[] = [];
    tier2InProgress: TaskList[] = [];
    tier3InProgress: TaskList[] = [];
    tier4InProgress: TaskList[] = [];
    tier5InProgress: TaskList[] = [];

    userId: number;

    tier1Expand: boolean;
    tier2Expand: boolean;
    tier3Expand: boolean;
    tier4Expand: boolean;
    tier5Expand: boolean;

    tier1Auth: TaskList[] = [];
    tier2Auth: TaskList[] = [];
    tier3Auth: TaskList[] = [];
    tier4Auth: TaskList[] = [];

    tier1SelectedItem: { eventId: number; tasks: Task.Object[] };
    tier2SelectedItem: { eventId: number; tasks: Task.Object[] };
    tier3SelectedItem: { eventId: number; tasks: Task.Object[] };
    tier4SelectedItem: { eventId: number; tasks: Task.Object[] };
    tier5SelectedItem: { eventId: number; tasks: Task.Object[] };

    tier1BarText: string;
    tier2BarText: string;
    tier3BarText: string;
    tier4BarText: string;
    tier5BarText: string;

    columns: Table.Column[] = [
        { id: "reference", header: "Reference", sortable: true, width: 95 },
        { id: "name", header: "Task Item", sortable: true, width: 110 },
        { id: "type", header: "Type", sortable: true, width: 80 },
        { id: "status", header: "Status", sortable: false, width: 80 }, // pending item  component text, not really can sortable
        { id: "flag", header: "Flagged", sortable: false, width: 70 },
        { id: "responseBy", header: "Respond By", sortable: true, width: 110 },
        { id: "firstDate", header: "First Date", sortable: true, width: 110 },
        { id: "action", header: "Action", sortable: false, width: 80 },
        { id: "assignTo", header: "Assign To", sortable: true, width: 140 },
        { id: "comments", header: "Comments", sortable: false, width: 150 },
    ];

    selectedItem: any = {};
    allTasksComplete: boolean = false;
    progressLevel: string;
    levels: string[] = [
        "Event Type and Organization Approvals",
        "Location Assignments and Approvals",
        "Resource Assignments and Approvals",
        "Requirement Approvals",
        "To Dos and FYIs",
    ];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        let find = this.columns.filter((i: Table.Column) => {
            return i.id === "reference";
        });
        if (!this.modelBean.optBean.showRelatedEvents) {
            find && find.length > 0 ? this.columns.splice(0, 1) : "";
        } else {
            find.length === 0
                ? this.columns.unshift({ id: "reference", header: "Reference", sortable: true, width: 95 })
                : "";
        }

        this.modelBean.messages = null; //no error message to show
        this.modelBean.isInit = true;

        this.tableConfigTier1 = {
            type: "unpaginated",
            columns: this.columns,
            dataSource: this.getEventTasksTier1,
        };

        this.tableConfigTier2 = {
            type: "unpaginated",
            columns: this.columns,
            dataSource: this.getEventTasksTier2,
        };

        this.tableConfigTier3 = {
            type: "unpaginated",
            columns: this.columns,
            dataSource: this.getEventTasksTier3,
        };

        this.tableConfigTier4 = {
            type: "unpaginated",
            columns: this.columns,
            dataSource: this.getEventTasksTier4,
        };

        this.tableConfigTier5 = {
            type: "unpaginated",
            columns: this.columns,
            dataSource: this.getEventTasksTier5,
        };

        this.init = true;
        this.detectChanges();
    }

    /* Tier1 tasks include Event Type NP and Organization NP, that are not FYI and Authorization block by NP task */

    @Bind
    async getEventTasksTier1(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("TaskListServiceGetEventTasks"); // When user refreshes invalidate cache
        let data = await this.modelBean.getRow;
        this.tier1Auth = this.fyiTasks(4, data, "tier1"); // check approve FYI  (can be one is FYI another one is approve)
        data = data.filter(
            (i: TaskList) =>
                (i.actions.objectType !== 4 &&
                    i.actions.objectType !== 6 &&
                    i.actions.objectType !== 8 &&
                    !i.actions.isTodo &&
                    i.actions.itemTypeId !== 4 &&
                    i.type !== "FYI") ||
                ((i.actions.objectType === 7 || i.actions.objectType === 2) &&
                    i.type === "FYI" &&
                    i.taskBlocked === "T"),
        );
        data = data.concat(this.tier1Auth);
        await this.sortData(data);

        const configs = data.map(this.mapConfigToRow);
        this.initTier1 = true;
        this.tier1Count = data.length;
        this.tier1InProgress = data.filter((i: TaskList) => i.status.itemName === "In Progress");
        this.tier1Block = data.filter((i: TaskList) => i.taskBlocked === "T");
        this.tier1Expand = this.tier1Count > 0 && this.tier1InProgress.length > 0;
        this.tier1BarText = this.getTaskBarText(
            1,
            this.tier1Block.length,
            this.tier1InProgress.length,
            this.tier1Count,
        );
        if (this.tier1Expand) this.progressLevel = "Event Type and Organization Approvals";
        return {
            rows: configs,
            totalRows: configs.length,
        };
    }

    /* Tier 2 is location approval. Once AP is done any 'approval required' location NP will require approval before tier3 is started
       Tier 2 FYIs with blocked by tier1
       Tier 2 FYIs with no blocked by  tier 1, go to TierToDo & FYIs
    */

    @Bind
    async getEventTasksTier2(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("TaskListServiceGetEventTasks"); // When user refreshes invalidate cache
        let data = await this.modelBean.getRow;
        this.tier2Auth = this.fyiTasks(4, data); // check approve FYI  (can be one is FYI another one is approve)
        data = data.filter(
            (i: TaskList) =>
                i.actions.objectType === 4 && i.actions.itemTypeId !== 4 && (i.type !== "FYI" || i.taskBlocked === "T"),
        );
        data = data.concat(this.tier2Auth);
        await this.sortData(data);

        const configs = data.map(this.mapConfigToRow);
        this.initTier2 = true;
        this.tier2Count = data.length;
        this.tier2Block = data.filter((i: TaskList) => i.taskBlocked === "T");
        this.tier2InProgress = data.filter((i: TaskList) => i.status.itemName === "In Progress");
        this.tier2Expand = this.tier2Count > 0 && this.tier2Block.length === 0 && this.tier2InProgress.length > 0;
        this.tier2BarText = this.getTaskBarText(
            2,
            this.tier2Block.length,
            this.tier2InProgress.length,
            this.tier2Count,
        );
        if (this.tier2Expand && !this.progressLevel) this.progressLevel = "Location Assignments and Approvals";
        this.cd.detectChanges();
        return {
            rows: configs,
            totalRows: configs.length,
        };
    }

    /* Tier 3 is resource approval. Once AP is done any 'approval required' resource NP will require approval before tier4 is started
       Tier 3 FYIs with blocked by tier2
       Tier 3 FYIs with no blocked by tier 2, go to TierToDo & FYIs
    */
    @Bind
    async getEventTasksTier3(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("TaskListServiceGetEventTasks"); // When user refreshes invalidate cache
        let data = await this.modelBean.getRow;
        this.tier3Auth = this.fyiTasks(6, data); // check approve FYI  (can be one is FYI another one is approve)
        data = data.filter(
            (i: any) =>
                i.actions.objectType === 6 && i.actions.itemTypeId !== 4 && (i.type !== "FYI" || i.taskBlocked === "T"),
        );
        data = data.concat(this.tier3Auth);
        await this.sortData(data);
        const configs = data.map(this.mapConfigToRow);
        this.initTier3 = true;
        this.tier3Count = data.length;
        this.tier3Block = data.filter((i: TaskList) => i.taskBlocked === "T");
        this.tier3InProgress = data.filter((i: TaskList) => i.status.itemName === "In Progress");
        this.tier3Expand = this.tier3Count > 0 && this.tier3Block.length === 0 && this.tier3InProgress.length > 0;
        if (this.tier3Expand && !this.progressLevel) {
            this.progressLevel = "Resource Assignments and Approvals";
        }
        this.tier3BarText = this.getTaskBarText(
            3,
            this.tier3Block.length,
            this.tier3InProgress.length,
            this.tier3Count,
        );
        return {
            rows: configs,
            totalRows: configs.length,
        };
    }

    /* Tier 4 is reqirements approval. Once AP is done any 'approval required' resource NP will require approval before tier4 is started
          Tier 4 FYIs with blocked by tier 1, 2 or 3
          Tier 4 FYIs with no blocked by tier 1, 2 or 3, go to TierToDo & FYIs
    */
    @Bind
    async getEventTasksTier4(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("TaskListServiceGetEventTasks"); // When user refreshes invalidate cache
        let data = await this.modelBean.getRow;
        this.tier4Auth = this.fyiTasks(8, data, "tier4"); // check approve FYI  (can be one is FYI another one is approve)
        data = data.filter(
            (i: any) =>
                (i.actions.objectType === 8 && i.actions.itemTypeId !== 4 && i.taskBlocked === "T") ||
                (i.actions.objectType === 8 && i.actions.itemTypeId !== 4 && i.type === "Authorization"),
        );
        data = data.concat(this.tier4Auth);
        await this.sortData(data);
        const configs = data.map(this.mapConfigToRow);
        this.initTier4 = true;
        this.tier4Count = data.length;
        this.tier4Block = data.filter((i: TaskList) => i.taskBlocked === "T");
        this.tier4InProgress = data.filter((i: TaskList) => i.status.itemName === "In Progress");
        this.tier4Expand = this.tier4Count > 0 && this.tier4Block.length === 0 && this.tier4InProgress.length > 0;
        if (this.tier4Expand && !this.progressLevel) this.progressLevel = "Requirement Approvals";
        this.tier4BarText = this.getTaskBarText(
            4,
            this.tier4Block.length,
            this.tier4InProgress.length,
            this.tier4Count,
        );
        return {
            rows: configs,
            totalRows: configs.length,
        };
    }

    /* TierToDo tasks include toDO, unassign and  FYI (FYIs with no blocked by other tasks */

    @Bind
    async getEventTasksTier5(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("TaskListServiceGetEventTasks"); // When user refreshes invalidate cache
        let data = await this.modelBean.getRow;
        data = data.filter(
            (i: TaskList) =>
                i.actions.isTodo || i.actions.itemTypeId === 4 || (i.type === "FYI" && i.taskBlocked !== "T"),
        );
        let getAuth: any[] = this.tier1Auth.concat(this.tier2Auth).concat(this.tier3Auth).concat(this.tier4Auth); // all tiers FYIs
        // filter the FYI with approval
        data = data.filter(function (a: TaskList) {
            return (
                getAuth.filter(function (b: TaskList) {
                    return a.actions.itemId == b.actions.itemId;
                }).length == 0
            );
        });

        await this.sortData(data);

        const configs = data.map(this.mapConfigToRow);
        this.initTier5 = true;
        this.tier5Count = data.length;
        this.tier5Expand = this.tier5Count > 0 && this.tier4Block.length === 0 && this.tier4InProgress.length === 0;

        if (this.tier5Expand && !this.progressLevel) this.progressLevel = "To Dos and FYIs";
        this.tier5InProgress = data.filter((i: TaskList) => i.status.itemName === "In Progress");
        this.tier5BarText = this.getTaskBarText(5, 0, this.tier5InProgress.length, this.tier5Count);

        if (
            (this.tier5BarText === "(0 Tasks)" || this.tier5BarText === "(Completed)") &&
            (this.tier1BarText === "(Completed)" || this.tier1BarText === "(0 Tasks)") &&
            (this.tier2BarText === "(Completed)" || this.tier2BarText === "(0 Tasks)") &&
            (this.tier3BarText === "(Completed)" || this.tier3BarText === "(0 Tasks)") &&
            (this.tier3BarText === "(Completed)" || this.tier4BarText === "(0 Tasks)")
        ) {
            this.allTasksComplete = true;
        }
        return {
            rows: configs,
            totalRows: configs.length,
        };
    }

    mapConfigToRow = (task: any): Table.Row => {
        let targets = task.assignedTo.itemName
            .split(";")
            .filter((target: any) => target !== " ")
            .map((target: any) => target);
        const contactNames: Table.Cell[] = targets.map((target: any) => ({ text: target }));

        //Want to still have contact list on the task item since the add assignees functionality needs it for security check
        task.taskItem.contacts = TaskNormalizeUtil.eventTaskContactToObj(task.assignedTo?.task?.approval_contact);

        if (task.actions.isTodo) {
            task.actions.assignedById === task.currentUserId || task.actions.assignedToId === task.currentUserId
                ? (task.readOnly = false)
                : (task.readOnly = true);
        } else {
            task.readOnly = !task.hasApproveDenyPerms;
        }

        if (task.taskBlocked && task.taskBlocked === "T") {
            return {
                id: task.actions.itemId,
                name: task.actions.taskName,
                cells: {
                    reference: { text: task.reference },
                    name: {
                        component: S25TaskEditComponent,
                        inputs: { taskModel: task.taskItem, showIcon: true, taskBlocked: true },
                        textValue: task.actions.taskName,
                    },
                    type: { text: task.type },
                    status: {
                        component: S25TaskInactiveComponent,
                        inputs: { task: task },
                    },

                    action: { text: "None" },
                    flag: {},
                    responseBy: {
                        component: S25EditableDateComponent,
                        inputs: { val: task.respondBy.date, readOnly: true },
                        textValue: task.respondBy.date.toString(),
                        sortValue: S25Util.date.toS25ISODateTimeStr(task.respondBy.date),
                    },
                    firstDate: {
                        component: S25EditableDateComponent,
                        inputs: { val: task.eventDate, readOnly: true },
                        textValue: task.eventDate.toString(),
                        sortValue: S25Util.date.toS25ISODateTimeStr(task.eventDate.date),
                    },
                    assignTo: {
                        component: S25TaskAssignComponent,
                        //inputs: { items: contactNames, taskItem: task.taskItem },
                        inputs: {
                            items: contactNames,
                            taskItem: task.taskItem,
                            approval: task?.assignedTo?.task,
                            taskType: task.actions.itemTypeId, //  FYI = 1, Authorization = 2.....
                        },
                        textValue: task.assignedTo.itemName,
                    },
                    comments: {
                        component: S25EditableTextareaComponent,
                        inputs: { val: task.comments.value, readOnly: task.readOnly },
                        outputs: {
                            valChange: (val: string, row: Table.Row) => {
                                if (task.actions.isTodo) {
                                    TaskService.putTodoComment(task.actions.itemId, val);
                                } else {
                                    TaskService.putEventTaskComment(task.actions.eventId, task.actions.itemId, val);
                                }
                            },
                        },
                    },
                },
            };
        } else {
            return {
                id: task.actions.itemId,
                name: task.actions.taskName,
                cells: {
                    reference: { text: task.reference },
                    name: {
                        component: S25TaskEditComponent,
                        textValue: task.actions.taskName,
                        inputs: { taskModel: task.taskItem, showIcon: true, taskBlocked: false },
                        outputs: {
                            onRefresh: (val: string, row: Table.Row) => {
                                let el: any = document;
                                TaskViewApi.reset(el);
                            },
                        },
                    },
                    type: { text: task.type },
                    status: {
                        component: S25TaskStatusComponent,
                        inputs: {
                            task: {
                                task_blocked: task.actions.taskBlocked,
                                task_state: task.status.itemStateId === 3 ? 3 : task.actions.itemStateId,
                                task_type: task.actions.itemTypeId,
                            },
                        },
                    },
                    action: {
                        component: S25TaskActionComponent,
                        inputs: {
                            assignedToId: task.actions.assignedToId,
                            taskState: task.actions.itemStateId, // InProgress = 1, Completed = 2....
                            taskId: task.actions.itemId,
                            taskBlocked: task.actions.taskBlocked,
                            itemCount: task.actions.itemCount,
                            taskType: task.actions.itemTypeId, //  FYI = 1, Authorization = 2.....
                            requesterId: task.actions.requesterId,
                            eventId: task.actions.eventId,
                            workflowChained: task.actions.workflowChained,
                            todoType: task.taskItem.todoType,
                            todoSubType: task.taskItem.todoSubType,
                        },
                        outputs: { stateChange: this.onTaskAction },
                    },
                    flag: {
                        component: S25CheckboxComponent,
                        inputs: { modelValue: task.flagged.value },
                        outputs: {
                            modelValueChange: (val: boolean, row: Table.Row) => {
                                TaskService.setFlag(task.actions.itemTypeId, task.actions.itemId, val);
                            },
                        },
                    },
                    responseBy: {
                        component: S25EditableDateComponent,
                        inputs: { val: task.respondBy.date, readOnly: !task.hasApproveDenyPerms },
                        outputs: {
                            valChange: (val: string, row: Table.Row) => {
                                if (task.actions.isTodo) {
                                    TaskService.putTodoDueDate(task.actions.itemId, val);
                                } else {
                                    TaskService.putEventTaskDueDate(task.actions.eventId, task.actions.itemId, val);
                                }
                            },
                        },
                        textValue: task.respondBy.date.toString(),
                        sortValue: S25Util.date.toS25ISODateTimeStr(task.respondBy.date),
                    },
                    firstDate: {
                        component: S25EditableDateComponent,
                        inputs: { val: task.eventDate, readOnly: true },
                        outputs: {
                            valChange: (val: string, row: Table.Row) => {
                                //just display date, do nothing here
                            },
                        },
                        textValue: task.eventDate.toString(),
                        sortValue: S25Util.date.toS25ISODateTimeStr(task.eventDate.date),
                    },
                    assignTo: {
                        component: S25TaskAssignComponent,
                        //inputs: { items: contactNames, taskItem: task.taskItem },
                        inputs: {
                            items: contactNames,
                            taskItem: task.taskItem,
                            approval: task?.assignedTo?.task,
                            taskType: task.actions.itemTypeId, //  FYI = 1, Authorization = 2.....
                        },
                        textValue: task.assignedTo.itemName,
                    },
                    comments: {
                        component: S25EditableTextareaComponent,
                        inputs: { val: task.comments.value, readOnly: task.readOnly },
                        outputs: {
                            valChange: (val: string, row: Table.Row) => {
                                if (task.actions.isTodo) {
                                    TaskService.putTodoComment(task.actions.itemId, val);
                                } else {
                                    TaskService.putEventTaskComment(task.actions.eventId, task.actions.itemId, val);
                                }
                            },
                        },
                    },
                },
            };
        }
    };

    @Bind
    onTaskAction(state: Task.State, row: Table.Row) {
        // Update task item column
        const taskItem = row.cells.name.instance as S25TaskEditComponent;
        if (taskItem) {
            taskItem.taskModel.itemStateId = state;
            taskItem.refresh();
        }
        // Update status column
        const status = row.cells.status.instance as S25TaskStatusComponent;
        if (status) {
            status.task.task_state = state;
            status.refresh();
        }
    }

    onChangeStatus(state: Task.State, row: Table.Row) {
        //Update status column
        const status = row.cells.status.instance as S25TaskStatusComponent;
        if (status) {
            status.task.task_state = state;
            status.refresh();
        }
    }

    private getRowsByTaskId(taskIds: number[]) {
        return this.modelBean.getRow.filter((f: any) => {
            return taskIds.includes(f.actions.itemId);
        });
    }

    selectedChange(selectItem: any, tier?: string) {
        let selectedItem: any = Array.from(selectItem);
        let ret: any = [];
        let count = 0;
        //check select item has hasApproveDenyPerms
        jSith.forEach(selectedItem, (key, i) => {
            let find = this.modelBean.getRow.find((f: any) => {
                return i && f.hasApproveDenyPerms === true && f.actions.itemId === i;
            });
            if (find) {
                ret.push(i);
            } else {
                count++, this.tableComponent.selected.delete(i);
            }
            return ret;
        });

        this.selectedItem = {
            eventId: this.modelBean.getRow[0].taskItem.eventId,
            tasks: this.getRowsByTaskId(ret).map((row: any) => row.taskItem),
        };

        count > 0 ? alert("You do not have permission to approve or deny on one or more of the selected tasks!") : "";

        switch (tier) {
            case "tier1":
                this.tier1SelectedItem = this.selectedItem;
                break;

            case "tier2":
                this.tier2SelectedItem = this.selectedItem;
                break;

            case "tier3":
                this.tier3SelectedItem = this.selectedItem;
                break;

            case "tier4":
                this.tier4SelectedItem = this.selectedItem;
                break;

            case "tier5":
                this.tier5SelectedItem = this.selectedItem;
                break;
        }
    }

    async successF() {
        await S25Util.delay(250);
        this.detectChanges();
    }

    async refreshF() {
        await this.resetF();
    }

    async onTaskButtonRefresh() {
        await this.resetF();
    }

    async resetF() {
        this.init = false;
        this.detectChanges();
        await S25Util.delay(250);
        this.ngOnInit();
    }

    getData() {
        return Promise.all([ContactService.getCurrentName(), ContactService.getCurrentId()]).then(
            ([userName, userId]) => {
                return { userName, userId };
            },
        );
    }

    // check any FYI is approved. there is a case FYI has one contact is FYI another one is approved
    fyiTasks(objectType: number, items: any, tier?: string) {
        let ret: any = [];
        if (tier && tier === "tier1") {
            items = items.filter(
                (i: any) =>
                    i.actions.objectType !== 4 &&
                    i.actions.objectType !== 6 &&
                    i.actions.objectType !== 8 &&
                    !i.actions.isTodo &&
                    i.actions.itemTypeId !== 4 &&
                    i.type === "FYI",
            );
        } else if (tier && tier === "tier4") {
            items = items.filter(
                (i: any) =>
                    i.actions.objectType === objectType &&
                    i.actions.itemTypeId !== 4 &&
                    i.type === "FYI" &&
                    i.taskBlocked !== "T",
            );
        } else {
            items = items.filter(
                (i: any) =>
                    i.actions.objectType === objectType &&
                    i.actions.itemTypeId !== 4 &&
                    i.type === "FYI" &&
                    i.taskBlocked !== "T",
            );
        }

        if (items.length > 0) {
            jSith.forEach(items, (key: any, i: any) => {
                if (i.assignedTo.task && i.assignedTo.task.approval_contact) {
                    let find = i.assignedTo.task.approval_contact.find((f: any) => {
                        return f && f.notification_type_id === 2;
                    });
                    find ? ret.push({ ...i }) : "";
                }
            });
        }

        return ret;
    }

    getTaskBarText(tier: number, block?: number, inPro?: number, count?: number) {
        let text: string = "";
        if (tier === 1) {
            if (count === 0) {
                text = "(0 Tasks)";
            } else if (inPro > 0) {
                text = "(" + inPro + " In Progress)";
            } else if (block > 0) {
                text = "(" + block + " Pending)";
            } else {
                text = "(Completed)";
            }
        } else if (tier === 2 || tier === 3 || tier === 4) {
            if (count === 0) {
                text = "(0 Tasks)";
            } else if (block > 0) {
                text = "(" + block + " Pending)";
            } else if (inPro > 0) {
                text = "(" + inPro + " In Progress)";
            } else {
                text = "(Completed)";
            }
        } else {
            if (count === 0) {
                text = "(0 Tasks)";
            } else if (inPro > 0) {
                text = "(" + inPro + " In Progress)";
            } else {
                text = "(Completed)";
            }
        }
        return text;
    }

    sortData(data: any[]) {
        return data.sort((a: TaskList, b: TaskList) => {
            const dateA = new Date(a.eventDate);
            const dateB = new Date(b.eventDate);
            return dateA.getTime() - dateB.getTime();
        });
    }

    detectChanges() {
        try {
            setTimeout(() => {
                this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
            }, 120);
        } catch (error: any) {}
    }
}
