import { NgModule } from "@angular/core";
import { S25TaskActionComponent } from "./s25.task.action.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { CommonModule } from "@angular/common";
import { S25TaskContactsPickerComponent } from "./s25.task.contacts.picker.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25ProgressBarModule } from "../s25-progress-bar/s25.progress.bar.module";
import { S25TaskEditComponent } from "./s25.task.edit.component";
import { S25TaskWorkflowComponent } from "./s25.task.workflow.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25TaskButtonsComponent } from "./s25.task.buttons.component";
import { S25TaskAssignComponent } from "./s25.task.assign.component";
import { S25SimpleCollapseModule } from "../s25-simple-collapse/s25.simple.collapse.module";
import { S25TaskInactiveComponent } from "./s25.task.inactive.component";
import { ModalCreateEditTaskComponent } from "./modal.create.edit.task.component";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25InfiniteScrollModule } from "../s25-infinite-scroll/s25.infinite.scroll.module";
import { S25PipesModule } from "../../pipes/s25.pipes.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { ModalModule } from "../modal/modal.module";
import { S25TaskStatusComponent } from "./s25.task.status.component";
import { S25EventTaskListComponent } from "./s25.event.task.list.component";

/*
 * Summary of tasks components and usage
 * Event Tasks
 * --Tiered Workflow component - task.workflow.component.ts
 * --Standard list -s25.event.task.list.component.ts
 *
 * Task Search results
 * -S25SearchResultsListComponent
 *  -S25TaskEditComponent > CreateEditTaskComponent > TaskActionCellFactory
 *  -S25TaskStatus Component
 *  -S25TaskActionComponent

 */
@NgModule({
    imports: [
        S25LoadingInlineModule,
        CommonModule,
        S25MultiselectModule,
        PopoverModule,
        S25ToggleButtonModule,
        S25TableModule,
        S25SimpleCollapseModule,
        S25EditableModule,
        S25InfiniteScrollModule,
        S25PipesModule,
        S25ModalModule,
        ModalModule,
        S25ProgressBarModule,
    ],
    exports: [
        S25TaskActionComponent,
        S25TaskContactsPickerComponent,
        S25TaskEditComponent,
        S25TaskWorkflowComponent,
        S25TaskButtonsComponent,
        S25TaskAssignComponent,
        S25TaskInactiveComponent,
        S25TaskStatusComponent,
        ModalCreateEditTaskComponent,
        S25EventTaskListComponent,
    ],
    declarations: [
        S25TaskActionComponent,
        S25TaskContactsPickerComponent,
        S25TaskEditComponent,
        S25TaskWorkflowComponent,
        S25TaskButtonsComponent,
        S25TaskAssignComponent,
        S25TaskInactiveComponent,
        S25TaskStatusComponent,
        ModalCreateEditTaskComponent,
        S25EventTaskListComponent,
    ],
    providers: [
        S25TaskActionComponent,
        S25TaskContactsPickerComponent,
        S25TaskEditComponent,
        S25TaskWorkflowComponent,
        S25TaskButtonsComponent,
        S25TaskAssignComponent,
        S25TaskInactiveComponent,
        S25TaskStatusComponent,
        ModalCreateEditTaskComponent,
        S25EventTaskListComponent,
    ],
})
export class S25TaskModule {}
