import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { TaskViewApi } from "../s25-task-view/s25.task.view.component";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { TaskChange, TaskService } from "../../services/task/task.service";
import { Task } from "../../pojo/Task";
import TaskListTaskItem = Task.TaskListTaskItem;
import { TaskNormalizeUtil } from "../../services/task/task.normalize.util";

/**
 * S25TaskButtonsComponent is usually used to complete multiple tasks at once, often displayed above and below a table
 */
@TypeManagerDecorator("s25-ng-task-buttons")
@Component({
    selector: "s25-ng-task-buttons",
    template: `
        @if (init) {
            <div>
                <s25-loading-inline [model]="{}"></s25-loading-inline>
                <div>
                    <button
                        class="aw-button aw-button--primary c-margin-right--single c-margin-bottom--half"
                        (click)="approve()"
                        [disabled]="!modelBean || !modelBean.tasks || modelBean.tasks.length === 0"
                    >
                        Approve Checked
                    </button>
                    <button
                        class="aw-button aw-button--primary"
                        (click)="deny(3)"
                        [disabled]="!modelBean || !modelBean.tasks || modelBean.tasks.length === 0"
                    >
                        Deny Checked
                    </button>
                </div>
            </div>
        }
    `,
})
export class S25TaskButtonsComponent implements OnInit {
    @Input() modelBean: { eventId: number; tasks: TaskListTaskItem[] };
    @Output() onRefresh = new EventEmitter();

    init: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        this.init = true;
    }

    approve() {
        this.onActionClick(Task.States.Assigned);
    }

    deny() {
        this.onActionClick(Task.States.Denied);
    }

    async onActionClick(action: Task.State) {
        S25LoadingApi.init(this.elementRef.nativeElement);
        let tasks: TaskChange[] = this.modelBean.tasks.map(TaskNormalizeUtil.taskListToTaskChange);

        const [resp, err] = await S25Util.Maybe(TaskService.changeTask(tasks, action));
        S25LoadingApi.destroy(this.elementRef.nativeElement);

        if (err) {
            S25Util.showError(err);
        }

        let el: any = document;
        TaskViewApi.reset(el);
    }
}
